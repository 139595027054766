<template>
    <div class="app-container">
        <div class="user-content">
            <linked-users :user="accountUser" />
        </div>
    </div>
</template>

<script>
import auth from '@/utils/auth';
import LinkedUsers from './LinkedUsers.vue';

export default {
    name: 'client',
    components: {
        LinkedUsers,
    },
    computed: {
        accountUser() {
            const user = {...auth.userData};
            user.id = auth.user.uid;
            return user;
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-content {
    max-width: 900px;
    margin: 0 auto;
}
</style>
